
import {
    computed, defineComponent, PropType, ref, Ref, watch
} from 'vue';
import { propertyBaseRouter, propertySubRouter } from '@/router/data';
import router from '@/router/index';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import {
    ActionType, ListActionItem,
    ListHeaderItem, filterEmptyCellValue,
    listPagination, RequestData
} from '@/components/common/list';
import {
    getMenus, getPersonnelPageData
} from '@/views/pm/personnel/util';
import HttpRequest from '@/util/axios.config';
import { ApartmentForm } from '@/components/view/pm/apartment-edit-dialog';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import addButton from '@/components/common/add-button/index.vue';
import { community } from '@/data';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import { ChangeWordList } from '@/methods/setText';
import { limitErrorImg } from '@/methods/basicFun';
import { personnelType } from './all.type';

const breadCrumb = ref([{ label: ChangeWordList.PMPersonnelToVisitor }]);
const staffList: Array<{ name: string; label: string }> = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Card',
    label: WordList.DeliveryRfCard
}];
export default defineComponent({
    components: {
        listPagination,
        secondaryNav,
        propertyBreadCrumb,
        addButton,
        EnlargeImage
    },
    props: {
        type: {
            type: String as PropType<personnelType>,
            default: 'tempKey'
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        // 办公下只显示tempkey相关
        const projectType = community.ProjectType;
        const secondMenus = ref(getMenus(propertyBaseRouter));
        watch(community.ProjectType, () => {
            breadCrumb.value = [{ label: ChangeWordList.PMPersonnelToVisitor }];
            secondMenus.value = getMenus(propertyBaseRouter);
        }, {
            immediate: true
        });
        // list 所需数据
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const defaultAction: Ref<Array<ActionType>> = ref([]);
        const deleteUrl = ref('');
        const keyOfCheck = ref('ID');
        const keyOfDelete = ref('ID');
        const action: Ref<Array<ListActionItem>> = ref([]);
        const infoPath = ref();
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        const activeNavItem = computed(() => props.type);

        const deliveryList: Ref<Array<{ name: string; label: string }>> = ref([{
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'Pin',
            label: WordList.ProperAllTextPIN
        }, {
            name: 'Card',
            label: WordList.DeliveryRfCard
        }]);

        watch([activeNavItem, computed(() => community.IsEncryptPin.value), community.isGetPmInfoComplete], () => {
            if (!community.isGetPmInfoComplete.value) {
                return;
            }
            if (activeNavItem.value !== 'tempKey') {
                keyOfDelete.value = 'UUID';
                keyOfCheck.value = 'UUID';
            } else {
                keyOfDelete.value = 'ID';
                keyOfCheck.value = 'ID';
            }
            const pageData = getPersonnelPageData()[activeNavItem.value];
            headers.value = pageData.header;
            console.log(headers.value);
            if (activeNavItem.value === 'tempKey' && community.IsEncryptPin.value === '1' && headers.value[2].name === 'QrCodeUrl') {
                headers.value.splice(2, 1);
            }
            if (community.IsEncryptPin.value === '1' && deliveryList.value[1].name === 'Pin') {
                deliveryList.value.splice(1, 1);
            }
            listRequestData.value = pageData.requestData;
            defaultAction.value = pageData.defaultAction;
            deleteUrl.value = pageData.deleteUrl;
            infoPath.value = pageData.infoPath;
        }, {
            immediate: true
        });
        const changePath = (key: string, path: string) => {
            router.push(path);
        };

        // const option: 'add'| 'edit' = 'add';
        const add = () => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.personnelHandle}?type=${props.type}&option=add`);
        };

        const info = (listItem: ApartmentForm) => {
            router.push(`${infoPath.value}?ID=${listItem.ID}`);
        };

        const edit = (listItem: ApartmentForm) => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.personnelHandle}?type=${props.type}&option=edit&optionUUID=${listItem.UUID}`);
        };

        const download = () => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.download = 'Staff_RFCard_Template.xlsx';
            a.href = '/download/template/Staff_RFCard_Template_PM.xlsx';
            a.click();
        };

        const file: Ref<any> = ref();
        const form: Ref<any> = ref();
        const setFile = () => {
            file.value.click();
        };

        const importRfCard = () => {
            const formData = new FormData(form.value);
            HttpRequest.post('v3/web/community/key/importStaffRfCard', formData, () => {
                updateToList.value += 1;
            });
            file.value.value = '';
        };

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const showEnlargeImage = (address: string) => {
            picAddress.value = address;
            isShowEnlargeImage.value = true;
        };

        return {
            headers,
            listRequestData,
            updateToList,
            changePath,
            secondMenus,
            activeNavItem,
            action,
            defaultAction,
            deleteUrl,
            info,
            breadCrumb,
            router,
            propertySubRouter,
            propertyBaseRouter,
            deliveryList,
            staffList,
            add,
            edit,
            filterEmptyCellValue,
            download,
            setFile,
            file,
            form,
            importRfCard,
            picAddress,
            showEnlargeImage,
            isShowEnlargeImage,
            saveParamInPath,
            searchList,
            projectType,
            setAllImg,
            keyOfDelete,
            keyOfCheck
        };
    }
});
