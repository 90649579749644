import {
    ListHeaderItem, RequestData, getSearch
} from '@/components/common/list';
import { community } from '@/data';
import { PersonnelPageData } from './all.type';

export default null;

function getMenus(basicRouter: string) {
    return community.ProjectType.value === 'office' ? [{
        label: WordList.DarshbordTmp,
        key: 'tempKey',
        path: `/${basicRouter}/personnel?type=tempKey`
    }, {
        label: WordList.DeliveryDeliveryAuth,
        key: 'deliveryAuth',
        path: `/${basicRouter}/personnel?type=deliveryAuth`
    }] : [{
        label: WordList.DarshbordTmp,
        key: 'tempKey',
        path: `/${basicRouter}/personnel?type=tempKey`
    }, {
        label: WordList.DeliveryDeliveryAuth,
        key: 'deliveryAuth',
        path: `/${basicRouter}/personnel?type=deliveryAuth`
    }, {
        label: WordList.DeliveryStaffAuth,
        key: 'staffAuth',
        path: `/${basicRouter}/personnel?type=staffAuth`
    }];
}
// list header 数据
const headerTemp: Array<ListHeaderItem> = [
    {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Description'
    }, {
        label: WordList.TmpKeyListTanleKey,
        name: 'Code'
    }, {
        label: WordList.TmpKeyManageListTanleQRCode,
        name: 'QrCodeUrl',
        type: 'customize'
    }, {
        label: WordList.EachDoorCounts,
        name: 'Count'
    }, {
        label: WordList.ProperAllTextRepeats,
        name: 'Repeats'
    }, {
        label: WordList.ProperAllTextCreatedBy,
        name: 'Creator'
    }, {
        label: WordList.TmpKeyManageListTanleBeginTime,
        name: 'BeginTime'
    }, {
        label: WordList.TabelTmpKeyInHtmlEndTime,
        name: 'EndTime'
    }
];
const headerDelivery: Array<ListHeaderItem> = [
    {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.ProperAllTextAuditCategoryAccess,
        name: 'AccessControl',
        type: 'customize'
    }, {
        label: WordList.ProperAllTextAccessGroup,
        name: 'AccessGroupName'
    }, {
        label: WordList.AccessFloor,
        name: 'AccessibleFloors'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];
const headerStaff: Array<ListHeaderItem> = [
    {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.AccessMethods,
        name: 'AccessMethods',
        type: 'customize'
    }, {
        label: WordList.ProperAllTextAccessGroup,
        name: 'AccessGroupName'
    }, {
        label: WordList.AccessFloor,
        name: 'AccessibleFloors'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];

const getPersonnelPageData = () => {
    // list requestData 数据
    const tempRequestData: RequestData = {
        url: `v3/web/${community.ProjectType.value}/tempKey/getList`,
        param: getSearch({
            searchKey: 'Description',
            searchValue: ''
        })
    };
    const deliverypRequestData: RequestData = {
        url: `v3/web/${community.ProjectType.value}/delivery/getList`,
        param: getSearch({
            searchKey: 'Name',
            searchValue: ''
        })
    };
    const staffRequestData: RequestData = {
        url: 'v3/web/community/staff/getList',
        param: getSearch({
            searchKey: 'Name',
            searchValue: ''
        })
    };

    const personnelPageData: PersonnelPageData = {
        tempKey: {
            header: headerTemp,
            requestData: tempRequestData,
            defaultAction: ['info', 'delete'],
            deleteUrl: `v3/web/${community.ProjectType.value}/tempKey/delete`,
            infoPath: '/propertyIndex/tempKeyInfo'
        },
        deliveryAuth: {
            header: headerDelivery,
            requestData: deliverypRequestData,
            defaultAction: ['edit', 'delete'],
            deleteUrl: `v3/web/${community.ProjectType.value}/delivery/delete`
        },
        staffAuth: {
            header: headerStaff,
            requestData: staffRequestData,
            defaultAction: ['edit', 'delete'],
            deleteUrl: 'v3/web/community/staff/delete'
        }
    };

    return personnelPageData;
};

export {
    getMenus,
    headerTemp, headerDelivery, headerStaff,
    getPersonnelPageData
};
